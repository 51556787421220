export const STORE_LOGIN_DETAILS = 'STORE_LOGIN_DETAILS';
export const LOGOUT = 'LOGOUT';
export const UPDATE_CHILD_DATA = 'UPDATE_CHILD_DATA';
export const UPDATE_PASSWORD_STATUS = 'UPDATE_PASSWORD_STATUS';
export const REMOVE_CHALLENGE_TEAM_ID = 'REMOVE_CHALLENGE_TEAM_ID';

export const STORE_CHILD_DETAILS = 'STORE_CHILD_DETAILS';
export const STORE_SCHOOLS_LIST = 'STORE_SCHOOLS_LIST';

export const UPDATE_CREDITS_MODAL = 'UPDATE_CREDITS_MODAL';
export const UPDATE_PASSWORD_MODAL = 'UPDATE_PASSWORD_MODAL';
export const UPDATE_PAST_JOURNEY_MODAL = 'UPDATE_PAST_JOURNEY_MODAL';

export const UPDATE_SHOW_PASSWORD_SET_RESPONSE =
  'UPDATE_SHOW_PASSWORD_SET_RESPONSE';
export const UPDATE_NETWORK_CONNECTION_STATE =
  'UPDATE_NETWORK_CONNECTION_STATE';
export const SET_OPENED_CLASS = 'SET_OPENED_CLASS';
export const SET_IP_DATA = 'SET_IP_DATA';
export const SET_SETTINGS_DIALOG = 'SET_SETTINGS_DIALOG';
export const SET_SUBSCRIPTION_ID = 'SET_SUBSCRIPTION_ID';
export const SET_SUBSCRIPTION_TRACKS = 'SET_SUBSCRIPTION_TRACKS';
export const SET_VISITOR_ID = 'SET_VISITOR_ID';
export const UPDATE_SHOW_REFERRAL_STRIP = 'UPDATE_SHOW_REFERRAL_STRIP';
export const UPDATE_SHOW_CHALLENGE_STRIP = 'UPDATE_SHOW_CHALLENGE_STRIP';
export const SET_SUPPORT_DIALOG = 'SET_SUPPORT_DIALOG';
export const SET_UTM_PARAMETERS = 'SET_UTM_PARAMETERS';
export const SET_SESSION_SDK = 'SET_SESSION_SDK';
export const UPDATE_XPLORE = 'UPDATE_XPLORE';
export const SET_SIGNUP_TYPE = 'SET_SIGNUP_TYPE';
export const HIDE_BOOK_DOUBT_POPOVER = 'HIDE_BOOK_DOUBT_POPOVER';
export const HIDE_SUMMER_CAMP_MODAL = 'HIDE_SUMMER_CAMP_MODAL';

export const SET_AGORA_CLIENT = 'SET_AGORA_CLIENT';
export const SET_AUDIO_STREAM = 'SET_AUDIO_STREAM';
export const SET_VIDEO_STREAM = 'SET_VIDEO_STREAM';
export const RESET_AUDIO_AND_VIDEO_TRACK = 'RESET_AUDIO_AND_VIDEO_TRACK';

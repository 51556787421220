const BlockedMicIcon = ({ className }) => (
  <svg
    className={className}
    width='35'
    height='36'
    viewBox='0 0 35 36'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M10 6C10 4.4087 10.6321 2.88258 11.7574 1.75736C12.8826 0.632141 14.4087 0 16 0C17.5913 0 19.1174 0.632141 20.2426 1.75736C21.3679 2.88258 22 4.4087 22 6V16C22 17.5913 21.3679 19.1174 20.2426 20.2426C19.1174 21.3679 17.5913 22 16 22C14.4087 22 12.8826 21.3679 11.7574 20.2426C10.6321 19.1174 10 17.5913 10 16V6Z'
      fill='white'
    />
    <path
      d='M7 13C7.26522 13 7.51957 13.1054 7.70711 13.2929C7.89464 13.4804 8 13.7348 8 14V16C8 18.1217 8.84285 20.1566 10.3431 21.6569C11.8434 23.1571 13.8783 24 16 24C18.1217 24 20.1566 23.1571 21.6569 21.6569C23.1571 20.1566 24 18.1217 24 16V14C24 13.7348 24.1054 13.4804 24.2929 13.2929C24.4804 13.1054 24.7348 13 25 13C25.2652 13 25.5196 13.1054 25.7071 13.2929C25.8946 13.4804 26 13.7348 26 14V16C26 18.4791 25.0792 20.8698 23.4162 22.7083C21.7533 24.5468 19.4666 25.7021 17 25.95V30H23C23.2652 30 23.5196 30.1054 23.7071 30.2929C23.8946 30.4804 24 30.7348 24 31C24 31.2652 23.8946 31.5196 23.7071 31.7071C23.5196 31.8946 23.2652 32 23 32H9C8.73478 32 8.48043 31.8946 8.29289 31.7071C8.10536 31.5196 8 31.2652 8 31C8 30.7348 8.10536 30.4804 8.29289 30.2929C8.48043 30.1054 8.73478 30 9 30H15V25.95C12.5334 25.7021 10.2467 24.5468 8.58376 22.7083C6.92077 20.8698 5.99997 18.4791 6 16V14C6 13.7348 6.10536 13.4804 6.29289 13.2929C6.48043 13.1054 6.73478 13 7 13Z'
      fill='white'
    />
    <rect
      x='18.25'
      y='19.25'
      width='15.029'
      height='15.5'
      rx='4.75'
      fill='#DD234C'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M25.765 28.3149L28.2018 30.8099C28.4463 31.0621 28.6053 31.0647 28.8543 30.8099L29.3432 30.3092C29.5827 30.064 29.599 29.9031 29.3432 29.6412L26.7633 27.0001L29.3434 24.359C29.5851 24.1108 29.5898 23.9433 29.3434 23.6908L28.8545 23.1903C28.6009 22.9306 28.4442 22.9426 28.2021 23.1903L25.765 25.6853L23.3281 23.1906C23.086 22.9428 22.9293 22.9308 22.6757 23.1906L22.1867 23.691C21.9402 23.9435 21.9446 24.111 22.1867 24.3592L24.7667 27.0001L22.1867 29.6412C21.931 29.9031 21.9446 30.064 22.1867 30.3092L22.6754 30.8099C22.9222 31.0647 23.0813 31.0621 23.3279 30.8099L25.765 28.3149Z'
      fill='white'
    />
    <rect
      x='18.25'
      y='19.25'
      width='15.029'
      height='15.5'
      rx='4.75'
      stroke='#191919'
      strokeWidth='1.5'
    />
  </svg>
);

export default BlockedMicIcon

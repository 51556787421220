import React, { Suspense, lazy, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import axios from 'axios';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import amplitude from 'amplitude-js';

import './App.css';
import './Util/util.scss';
import { fetchFromExternalLinks } from './services/apiService';
import {
  detectBrowser,
  detectOS,
  getScreenResolution,
  detectDeviceType,
} from './Util/getDeviceInformation';
import {
  updateNetworkConnectionState,
  setVisitorId,
  setSupportDialog,
  setUtmParameters,
  setIpData,
} from './actions/generalActions';
import FullPageLoader from './components/Common/FullPageLoader/FullPageLoader';
import OfflineScreen from './components/OfflineScreen/OfflineScreen';
import Settings from './components/Settings/Settings';
import NeedSupportModal from './components/NeedSupportModal/NeedSupportModal';
const LandingPage = lazy(() => import('./components/LandingPage/LandingPage'));
const CurriculumPage = lazy(() =>
  import('./components/CurriculumPage/CurriculumPage')
);
const ContactUs = lazy(() => import('./components/ContactUs/ContactUs'));
const AboutUs = lazy(() => import('./components/AboutUs/AboutUs'));
const OurApproach = lazy(() => import('./components/OurApproach/OurApproach'));
const Home = lazy(() => import('./components/Home/Home'));
const Activity = lazy(() => import('./components/Activity/ActivityArena'));
const Homework = lazy(() => import('./components/Activity/Homework/Homework'));
const Assessment = lazy(() =>
  import('./components/Activity/Assessment/Assessment')
);
const ActivitySummary = lazy(() =>
  import('./components/Activity/Summary/Summary')
);
const Classroom = lazy(() => import('./components/Classroom/Classroom'));
const AddFirstChild = lazy(() =>
  import('./components/AddFirstChild/AddFirstChild')
);
const ProfilePage = lazy(() => import('./components/ProfilePage/ProfilePage'));
const ReferralPage = lazy(() =>
  import('./components/ReferralPage/ReferralPage')
);
const XploreSignupPage = lazy(() =>
  import('./components/XploreSignupPage/XploreSignupPage')
);
const WorkshopRegistration = lazy(() =>
  import('./components/Workshop/Registration/Registration')
);
const WorkshopSlotSelection = lazy(() =>
  import('./components/Workshop/SlotSelection/SlotSelection')
);
const MonthlyFeedback = lazy(() =>
  import('./components/MonthlyFeedback/MonthlyFeedback')
);
const LqChallengeDashboard = lazy(() =>
  import('./components/LqChallengeDashboard/LqChallengeDashboard')
);
const LqChallengeRules = lazy(() =>
  import('./components/LqChallengeRules/LqChallengeRules')
);
const LqChallengeSignup = lazy(() =>
  import('./components/LqChallengeSignup/LqChallengeSignup')
);
const LqPrepClasses = lazy(() =>
  import('./components/LqPrepClasses/LqPrepClasses')
);
const LqChampSignup = lazy(() =>
  import('./components/LqChampSignup/LqChampSignup')
);
const LqFortnightChallenge = lazy(() =>
  import('./components/LqFortnightChallenge/LqFortnightChallenge')
);
const LqFortnightSignup = lazy(() =>
  import('./components/LqFortnightSignup/LqFortnightSignup')
);

const PricingPage = lazy(() => import('./components/PricingPage/PricingPage'));
const MasterClass = lazy(() => import('./components/MasterClass/MasterClass'));

const tawkToSupportedPages = [
  '/',
  '/dashboard',
  '/sign-up',
  '/signup',
  '/signup1',
  '/signup2',
  '/signup3',
  '/signup-srkg',
  '/signup-lq',
  '/without-otp',
  '/without-otp1',
  '/signup-master-class',
];

const PrivateRoute = (props) => {
  const {
    key,
    isLoggedIn,
    sessionToken,
    path,
    component,
    timezone,
    studentId,
    redirectionPath,
  } = props;

  if (isLoggedIn) {
    Sentry.setUser({ id: studentId });
    amplitude.setUserId(studentId);
    axios.defaults.headers.common['Cache-Control'] = 'no-cache';
    axios.defaults.headers.common['Session-Token'] = sessionToken;
    axios.defaults.headers.common['timezone'] = timezone;
    axios.defaults.headers.common['platform'] = detectDeviceType();
    axios.defaults.headers.common['platform-os'] = detectOS();
    axios.defaults.headers.common['browser-details'] = detectBrowser();
    axios.defaults.headers.common['screen-resolution'] = getScreenResolution();

    return <Route key={key} exact path={path} component={component} />;
  }

  return (
    <Redirect
      to={redirectionPath ? redirectionPath + window.location.search : '/'}
    />
  );
};

const theme = createMuiTheme({
  typography: {
    fontFamily: 'Montserrat',
  },
  palette: {
    primary: {
      main: '#240046',
    },
    secondary: {
      main: '#BF5AF2',
    },
  },
});

const App = (props) => {
  const [loader, setLoader] = useState(true);

  const {
    isLoggedIn,
    sessionToken,
    timezone,
    currencyCode,
    settingsDialog,
    isOffline,
    updateNetworkConnectionState,
    studentId,
    setVisitorId,
    supportDialog,
    utmParameters,
    setSupportDialog,
    setUtmParameters,
    setIpData,
    lastIpDataUpdated,
  } = props;
  const location = useLocation();

  const getUtmParameter = () => {
    const utmParamsJson = [];
    const queryParams = new URLSearchParams(window.location.search).entries();

    for (const [key, value] of queryParams) {
      if (key.includes('utm')) utmParamsJson.push({ [key]: value });
    }
    if (location.pathname === '/signup2' || queryParams?.fbclid) {
      if (utmParamsJson?.utm_source)
        utmParamsJson.utm_source = 'facebook_pixel';
      else utmParamsJson.push({ utm_source: 'facebook_pixel' });
    }

    if (utmParamsJson.length > 0) {
      axios.defaults.headers.common['Utm-Parameters'] =
        JSON.stringify(utmParamsJson);
      setUtmParameters(JSON.stringify(utmParamsJson));
    } else if (utmParameters)
      axios.defaults.headers.common['Utm-Parameters'] = utmParameters;
  };

  const fetchIpData = async () => {
    const res = await fetchFromExternalLinks(
      'https://ipwhois.pro/json/?key=cFq2N2Qvyg8UfS9q'
    );

    if (res.data && res.data.timezone) {
      const { country_phone, country_code, timezone, currency_code } = res.data;

      setIpData({
        timezone,
        countryPhone: country_phone,
        countryCode: country_code,
        currencyCode: currency_code,
      });
    }
  };

  useEffect(() => {
    (async () => {
      if (isOffline && navigator.onLine) updateNetworkConnectionState(false);
      setVisitorId();
      getUtmParameter();

      if (new Date().getTime() - (lastIpDataUpdated || 0) >= 604800000)
        await fetchIpData();

      setLoader(false);
    })();
  }, []);

  useEffect(() => {
    if (Object.keys(window.Tawk_API).length > 1) {
      if (tawkToSupportedPages.includes(location.pathname))
        window.Tawk_API.showWidget();
      else window.Tawk_API.hideWidget();
    }
    if (supportDialog) setSupportDialog(false);
  }, [location.pathname]);

  return loader ? (
    <FullPageLoader />
  ) : (
    <div className='App'>
      <OfflineScreen />
      <ThemeProvider theme={theme}>
        <Suspense fallback={<FullPageLoader />}>
          {settingsDialog?.show && <Settings />}
          {supportDialog && <NeedSupportModal />}
          <Switch>
            <Route exact path='/' component={LandingPage} />
            <Route exact path='/curriculum' component={CurriculumPage} />
            <Route exact path='/contact-us' component={ContactUs} />
            <Route exact path='/about-us' component={AboutUs} />
            <Route exact path='/our-approach' component={OurApproach} />
            <Route
              exact
              path='/activity-admin-view'
              component={ActivitySummary}
            />
            <Route exact path='/lq-xplore' component={XploreSignupPage} />
            <Route exact path='/monthly-feedback' component={MonthlyFeedback} />
            <Route
              exact
              path='/summer-workshop/registration'
              component={WorkshopRegistration}
            />
            <Route
              exact
              path='/lq-challenge-signup'
              component={LqChallengeSignup}
            />
            <Route
              exact
              path='/lq-challenge-rules'
              component={LqChallengeRules}
            />
            <Route exact path='/lq-prep-classes' component={LqPrepClasses} />
            <Route exact path='/lq-champ' component={LqChampSignup} />
            <Route exact path='/challenge' component={LqFortnightSignup} />
            <PrivateRoute
              exact
              path='/lq-fortnight-challenge'
              component={LqFortnightChallenge}
              {...{ isLoggedIn, sessionToken, timezone, studentId }}
            />
            <PrivateRoute
              exact
              path='/lq-challenge'
              component={LqChallengeDashboard}
              {...{ isLoggedIn, sessionToken, timezone, studentId }}
            />
            <PrivateRoute
              path='/add-first-child'
              component={AddFirstChild}
              {...{ isLoggedIn, sessionToken, timezone, studentId }}
            />
            <PrivateRoute
              key='dashboard'
              path='/dashboard'
              component={Home}
              {...{
                isLoggedIn,
                sessionToken,
                timezone,
                studentId,
                currencyCode,
              }}
            />
            <PrivateRoute
              key='past-journey'
              path='/past-journey'
              component={Home}
              {...{ isLoggedIn, sessionToken, timezone, studentId }}
            />
            <PrivateRoute
              path='/activity'
              component={Activity}
              {...{ isLoggedIn, sessionToken, timezone, studentId }}
            />
            <PrivateRoute
              path='/homework'
              component={Homework}
              {...{ isLoggedIn, sessionToken, timezone, studentId }}
            />
            <PrivateRoute
              path='/assessment'
              component={Assessment}
              {...{ isLoggedIn, sessionToken, timezone, studentId }}
            />
            <PrivateRoute
              path='/summary'
              component={ActivitySummary}
              {...{ isLoggedIn, sessionToken, timezone, studentId }}
            />
            <PrivateRoute
              path='/classroom/:ssid'
              component={Classroom}
              {...{ isLoggedIn, sessionToken, timezone, studentId }}
            />
            <PrivateRoute
              path='/profile'
              component={ProfilePage}
              {...{ isLoggedIn, sessionToken, timezone, studentId }}
            />
            <PrivateRoute
              path='/my-referral'
              component={ReferralPage}
              {...{ isLoggedIn, sessionToken, timezone, studentId }}
            />
            <PrivateRoute
              path='/summer-workshop'
              component={WorkshopSlotSelection}
              {...{
                isLoggedIn,
                sessionToken,
                timezone,
                currencyCode,
                studentId,
              }}
              redirectionPath='/summer-workshop/registration'
            />
            <PrivateRoute
              path='/pricing'
              component={PricingPage}
              {...{ isLoggedIn, sessionToken, timezone, studentId }}
            />
            <PrivateRoute
              exact
              path='/masterclass'
              component={MasterClass}
              {...{ isLoggedIn, sessionToken, timezone, studentId }}
            />
          </Switch>
        </Suspense>
      </ThemeProvider>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.loginReducer.isLoggedIn,
  sessionToken: state.loginReducer.userDetails.session_token,
  timezone: state.generalReducer.timezone,
  settingsDialog: state.generalReducer.settingsDialog,
  studentId: state.loginReducer.userDetails.student_id,
  isOffline: state.generalReducer.isOffline,
  supportDialog: state.generalReducer.supportDialog,
  currencyCode: state.generalReducer.currencyCode,
  utmParameters: state.generalReducer.utmParameters,
  lastIpDataUpdated: state.generalReducer.lastIpDataUpdated,
});

export default connect(mapStateToProps, {
  updateNetworkConnectionState,
  setVisitorId,
  setSupportDialog,
  setUtmParameters,
  setIpData,
})(App);

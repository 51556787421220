import {
  SET_OPENED_CLASS,
  SET_IP_DATA,
  UPDATE_SHOW_PASSWORD_SET_RESPONSE,
  SET_SETTINGS_DIALOG,
  UPDATE_NETWORK_CONNECTION_STATE,
  SET_SUBSCRIPTION_ID,
  SET_VISITOR_ID,
  UPDATE_SHOW_REFERRAL_STRIP,
  UPDATE_SHOW_CHALLENGE_STRIP,
  SET_SUBSCRIPTION_TRACKS,
  SET_SUPPORT_DIALOG,
  SET_UTM_PARAMETERS,
  SET_SESSION_SDK,
  UPDATE_XPLORE,
  SET_SIGNUP_TYPE,
  HIDE_BOOK_DOUBT_POPOVER,
  HIDE_SUMMER_CAMP_MODAL,
} from '../actions/types';

const initialState = {
  openedClass: null,
  timezone: 'Asia/Calcutta',
  countryPhone: '+91',
  countryCode: 'IN',
  showPasswordSetResponse: false,
  subscriptionId: null,
  subscriptionTracks: [],
  settingsDialog: false,
  isOffline: false,
  visitorId: null,
  showReferralStrip: true,
  showChallengeStrip: true,
  supportDialog: false,
  currencyCode: 'INR',
  utmParameters: null,
  sessionSdk: 'ag',
  isXplore: false,
  signupType: null,
  bookDoubtPopover: true,
  isHideSummerCampModal: false,
};

const generalReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_OPENED_CLASS:
      return {
        ...state,
        openedClass: action.payload,
      };
    case SET_IP_DATA:
      return {
        ...state,
        ...action.payload,
        lastIpDataUpdated: new Date().getTime(),
      };
    case UPDATE_SHOW_PASSWORD_SET_RESPONSE:
      return {
        ...state,
        showPasswordSetResponse: action.payload,
      };
    case SET_SETTINGS_DIALOG:
      return {
        ...state,
        settingsDialog: action.payload,
      };
    case UPDATE_NETWORK_CONNECTION_STATE:
      return {
        ...state,
        isOffline: action.payload,
      };
    case SET_SUBSCRIPTION_ID:
      return {
        ...state,
        subscriptionId: action.payload,
      };
    case SET_SUBSCRIPTION_TRACKS:
      return {
        ...state,
        subscriptionTracks: action.payload,
      };
    case SET_VISITOR_ID:
      return {
        ...state,
        visitorId: action.payload,
      };
    case UPDATE_SHOW_REFERRAL_STRIP:
      return {
        ...state,
        showReferralStrip: action.payload,
      };
    case UPDATE_SHOW_CHALLENGE_STRIP:
      return {
        ...state,
        showChallengeStrip: action.payload,
      };
    case SET_SUPPORT_DIALOG:
      return {
        ...state,
        supportDialog: action.payload,
      };
    case SET_UTM_PARAMETERS:
      return {
        ...state,
        utmParameters: action.payload,
      };
    case SET_SESSION_SDK:
      return {
        ...state,
        sessionSdk: action.payload,
      };
    case UPDATE_XPLORE:
      return {
        ...state,
        isXplore: action.payload,
      };
    case SET_SIGNUP_TYPE:
      return {
        ...state,
        signupType: action.payload,
      };
    case HIDE_BOOK_DOUBT_POPOVER:
      return {
        ...state,
        bookDoubtPopover: false,
      };
    case HIDE_SUMMER_CAMP_MODAL:
      return {
        ...state,
        isHideSummerCampModal: false,
      };
    default:
      return state;
  }
};

export default generalReducer;

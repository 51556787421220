import React from 'react';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import './style.scss';
import MicInstructionIcon from './icons/MicInstructionIcon';
import CamInstructionIcon from './icons/CamInstructionIcon';
import BlockedCamIcon from './icons/BlockedCamIcon';
import BlockedMicIcon from './icons/BlockedMicIcon';
import RightTopArrowIcon from './icons/LeftTopArrowIcon';
import Row from '../../../Elements/Flex/Row';
import Col from '../../../Elements/Flex/Col';

const FireFoxModal = (props) => {
  const { handleClose, instructionType } = props;

  return (
    <Col className='firefox-permission-container'>
      <IconButton size='small' className='close-btn' onClick={handleClose}>
        <Close className='close-icon' />
      </IconButton>
      <RightTopArrowIcon className='left-top-arrow' />
      <Col className='instructions'>
        {instructionType === 'mic' ? (
          <MicInstructionIcon className='firefox-instruction' />
        ) : (
          <CamInstructionIcon className='firefox-instruction' />
        )}
        <h2 className='title'>
          Your{' '}
          {`${
            instructionType === 'mic'
              ? 'Mic'
              : instructionType === 'cam'
              ? 'Camera'
              : 'Microphone'
          }`}{' '}
          is blocked
        </h2>
        <p className='subtitle'>
          LogIQids needs access to your{' '}
          {`${
            instructionType === 'mic'
              ? 'Mic'
              : instructionType === 'cam'
              ? 'Camera'
              : 'Microphone'
          }`}
          . To use LogIQids -
        </p>
        <Col className='steps'>
          <Row className='step align-center'>
            <Row className='step-no'>1</Row>
            <Row className='align-center'>
              Click on {`${instructionType === 'mic' ? 'Mic' : 'Camera'}`}{' '}
              Blocked icon{' '}
              {instructionType === 'mic' ? (
                <BlockedMicIcon className='blocked-icon' />
              ) : (
                <BlockedCamIcon className='blocked-icon' />
              )}{' '}
              in your browser's address bar
            </Row>
          </Row>
          <Row className='step align-center'>
            <Row className='step-no'>2</Row>
            <p>
              Click on the cross icon(x) in the blocked status for {' '}
              {`${instructionType === 'mic'
                  ? 'microphone'
                  : 'camera'
              }`}
            </p>
          </Row>
          <Row className='step align-center'>
            <Row className='step-no'>3</Row>
            <p>Refresh the page and Allow the permission to continue.</p>
          </Row>
        </Col>
      </Col>
    </Col>
  );
};

export default FireFoxModal;

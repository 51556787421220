import React from 'react';

import './style.scss';
import { detectBrowser } from '../../../Util/getDeviceInformation';
import DefaultModal from './DefaultModal/DefaultModal';
import ChromeModal from './ChromeModal/ChromeModal';
import FireFoxModal from './FireFoxModal/FireFoxModal';

const PermissionModal = (props) => {
  const browser = detectBrowser();

  return (
    <div className='permission-instruction-container'>
      {browser.indexOf('Chrome') !== -1 || browser.indexOf('Edg') !== -1 ? (
        <ChromeModal {...props} />
      ) : browser.indexOf('Firefox') !== -1 ? (
        <FireFoxModal {...props} />
      ) : (
        <DefaultModal {...props} />
      )}
    </div>
  );
};

export default PermissionModal;

const BlockedMicIcon = ({ className }) => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
  >
    <path
      d='M13.5802 10.7482C13.8513 10.2044 13.9949 9.60589 14.0002 8.99822V3.99822C13.9891 3.01042 13.6129 2.06164 12.9441 1.33466C12.2752 0.607684 11.361 0.153924 10.3775 0.0607924C9.39407 -0.0323388 8.41093 0.241747 7.6175 0.830253C6.82406 1.41876 6.27646 2.28005 6.08019 3.24822L13.5802 10.7482ZM17.0002 8.99822C17.0002 8.733 16.8948 8.47865 16.7073 8.29111C16.5198 8.10358 16.2654 7.99822 16.0002 7.99822C15.735 7.99822 15.4806 8.10358 15.2931 8.29111C15.1055 8.47865 15.0002 8.733 15.0002 8.99822C14.9973 9.87188 14.7589 10.7286 14.3102 11.4782L15.7802 12.9982C16.5849 11.8203 17.0106 10.4247 17.0002 8.99822ZM10.0002 12.9982H10.1602L6.00019 8.82822V8.99822C6.00019 10.0591 6.42161 11.0765 7.17176 11.8266C7.9219 12.5768 8.93932 12.9982 10.0002 12.9982ZM18.7102 17.2882L2.71019 1.28822C2.61695 1.19498 2.50626 1.12102 2.38443 1.07056C2.26261 1.0201 2.13204 0.99413 2.00019 0.99413C1.86833 0.99413 1.73776 1.0201 1.61594 1.07056C1.49411 1.12102 1.38342 1.19498 1.29019 1.28822C1.10188 1.47652 0.996094 1.73192 0.996094 1.99822C0.996094 2.26452 1.10188 2.51992 1.29019 2.70822L17.2902 18.7082C17.3831 18.8019 17.4937 18.8763 17.6156 18.9271C17.7375 18.9779 17.8682 19.004 18.0002 19.004C18.1322 19.004 18.2629 18.9779 18.3848 18.9271C18.5066 18.8763 18.6172 18.8019 18.7102 18.7082C18.8039 18.6153 18.8783 18.5047 18.9291 18.3828C18.9798 18.2609 19.006 18.1302 19.006 17.9982C19.006 17.8662 18.9798 17.7355 18.9291 17.6136C18.8783 17.4918 18.8039 17.3812 18.7102 17.2882Z'
      fill='white'
    />
    <path
      d='M13 18H11V15.92C11.5659 15.8412 12.12 15.6935 12.65 15.48L11.05 13.88C10.7058 13.9605 10.3535 14.0007 10 14C8.67392 14 7.40215 13.4732 6.46447 12.5355C5.52678 11.5979 5 10.3261 5 9C5 8.73478 4.89464 8.48043 4.70711 8.29289C4.51957 8.10536 4.26522 8 4 8C3.73478 8 3.48043 8.10536 3.29289 8.29289C3.10536 8.48043 3 8.73478 3 9C3.00197 10.6818 3.60941 12.3068 4.71118 13.5775C5.81295 14.8482 7.3354 15.6797 9 15.92V18H7C6.73478 18 6.48043 18.1054 6.29289 18.2929C6.10536 18.4804 6 18.7348 6 19C6 19.2652 6.10536 19.5196 6.29289 19.7071C6.48043 19.8946 6.73478 20 7 20H13C13.2652 20 13.5196 19.8946 13.7071 19.7071C13.8946 19.5196 14 19.2652 14 19C14 18.7348 13.8946 18.4804 13.7071 18.2929C13.5196 18.1054 13.2652 18 13 18Z'
      fill='white'
    />
  </svg>
);

export default BlockedMicIcon;

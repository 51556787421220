import {
  STORE_LOGIN_DETAILS,
  UPDATE_CHILD_DATA,
  UPDATE_PASSWORD_STATUS,
  REMOVE_CHALLENGE_TEAM_ID,
} from '../actions/types';

const initialState = {
  userDetails: {},
  isLoggedIn: false,
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case STORE_LOGIN_DETAILS:
      return {
        ...state,
        userDetails: action.payload,
        isLoggedIn: true,
      };
    case UPDATE_CHILD_DATA:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          ...action.payload,
          child_added: true,
        },
      };
    case UPDATE_PASSWORD_STATUS:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          password_set: true,
        },
      };
    case REMOVE_CHALLENGE_TEAM_ID:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          challenge_team_id: undefined,
        },
      };
    default:
      return state;
  }
};

export default loginReducer;

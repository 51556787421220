import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import amplitude from 'amplitude-js';

import './index.css';
import App from './App';
import { store, persistor } from './store';
import { initializeFirebase } from './Util/firebaseFunctions';

const elem = document.getElementById('root');

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://41411ec8a00b40b38d4df81c50e17582@o1058891.ingest.sentry.io/6046920',
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.NODE_ENV,
    normalizeDepth: 10,
    tracesSampleRate: 1.0,
  });

  amplitude.getInstance().init('46cf4749d9fce65ef1bfe41c843bb584');
  const identify = new amplitude.Identify();
  amplitude.getInstance().identify(identify);
}

initializeFirebase();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <App />
        </Router>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  elem
);

import {
  SET_AGORA_CLIENT,
  SET_AUDIO_STREAM,
  SET_VIDEO_STREAM,
  RESET_AUDIO_AND_VIDEO_TRACK,
} from '../actions/types';

const initialState = {
  client: undefined,
  audioTrack: undefined,
  videoTrack: undefined,
};

const agoraReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_AGORA_CLIENT:
      return {
        ...state,
        client: action.payload,
      };
    case SET_AUDIO_STREAM:
      return {
        ...state,
        audioTrack: action.payload,
      };
    case SET_VIDEO_STREAM:
      return {
        ...state,
        videoTrack: action.payload,
      };
    case RESET_AUDIO_AND_VIDEO_TRACK:
      return {
        ...state,
        videoTrack: undefined,
        audioTrack: undefined,
      };
    default:
      return state;
  }
};

export default agoraReducer;

import { combineReducers } from 'redux';

import loginReducer from './loginReducer';
import firstChildReducer from './firstChildReducer';
import headerReducer from './headerReducer';
import generalReducer from './generalReducer';
import agoraReducer from './agoraReducer';

const allReducers = combineReducers({
  loginReducer,
  firstChildReducer,
  headerReducer,
  generalReducer,
  agoraReducer,
});

const rootReducer = (state, action) =>
  allReducers(
    action.type === 'LOGOUT'
      ? {
          generalReducer: {
            utmParameters: state?.generalReducer?.utmParameters,
            timezone: 'Asia/Calcutta',
            countryPhone: '+91',
            countryCode: 'IN',
            currencyCode: 'INR',
          },
        }
      : state,
    action
  );

export default rootReducer;

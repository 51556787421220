const BlockedCamIcon = ({ className }) => (
  <svg
    className={className}
    width='38'
    height='36'
    viewBox='0 0 38 36'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M28.7107 8.30981L23.1107 11.4654V7.10981C23.1107 6.63831 22.9234 6.18613 22.59 5.85273C22.2566 5.51933 21.8044 5.33203 21.3329 5.33203H5.3329C4.38991 5.33203 3.48554 5.70663 2.81874 6.37343C2.15195 7.04023 1.77734 7.94459 1.77734 8.88759V23.1098C1.77734 24.0528 2.15195 24.9572 2.81874 25.624C3.48554 26.2908 4.38991 26.6654 5.3329 26.6654H21.3329C21.8044 26.6654 22.2566 26.4781 22.59 26.1447C22.9234 25.8113 23.1107 25.3591 23.1107 24.8876V20.5143L28.7107 23.6698C28.8543 23.7637 29.0202 23.8181 29.1916 23.8276C29.3629 23.837 29.5337 23.8012 29.6869 23.7238C29.84 23.6463 29.9701 23.5299 30.064 23.3862C30.1579 23.2426 30.2123 23.0767 30.2218 22.9054V9.06536C30.2108 8.89477 30.1553 8.73003 30.0609 8.58757C29.9664 8.44511 29.8362 8.32991 29.6833 8.25346C29.5304 8.17701 29.3601 8.14199 29.1895 8.15187C29.0188 8.16176 28.8537 8.21622 28.7107 8.30981Z'
      fill='white'
    />
    <rect
      x='21.25'
      y='19.25'
      width='15.029'
      height='15.5'
      rx='4.75'
      fill='#DD234C'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M28.765 28.3149L31.2018 30.8099C31.4463 31.0621 31.6053 31.0647 31.8543 30.8099L32.3432 30.3092C32.5827 30.064 32.599 29.9031 32.3432 29.6412L29.7633 27.0001L32.3434 24.359C32.5851 24.1108 32.5898 23.9433 32.3434 23.6908L31.8545 23.1903C31.6009 22.9306 31.4442 22.9426 31.2021 23.1903L28.765 25.6853L26.3281 23.1906C26.086 22.9428 25.9293 22.9308 25.6757 23.1906L25.1867 23.691C24.9402 23.9435 24.9446 24.111 25.1867 24.3592L27.7667 27.0001L25.1867 29.6412C24.931 29.9031 24.9446 30.064 25.1867 30.3092L25.6754 30.8099C25.9222 31.0647 26.0813 31.0621 26.3279 30.8099L28.765 28.3149Z'
      fill='white'
    />
    <rect
      x='21.25'
      y='19.25'
      width='15.029'
      height='15.5'
      rx='4.75'
      stroke='#181818'
      strokeWidth='1.5'
    />
  </svg>
);

export default BlockedCamIcon;

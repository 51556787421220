import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import * as Sentry from "@sentry/react";

import rootReducer from './reducers';

const persistConfig = {
  key: 'root',
  storage,
};
const intialState = {};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

const store = createStore(
  persistedReducer,
  intialState,
  composeWithDevTools(applyMiddleware(thunk), sentryReduxEnhancer)
);

const persistor = persistStore(store);

export { persistor, store };

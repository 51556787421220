import React, { useEffect } from 'react';
import lottie from 'lottie-web';

import './style.scss';
import loader from '../../../lottie/loader.json';

const Loader = () => {
  useEffect(() => {
    lottie.loadAnimation({
      container: document.getElementById('loader'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: loader,
    });
  }, []);

  return <div id='loader' />;
};

export default Loader;

import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import './style.scss';
import { get } from 'lodash';
import { updateNetworkConnectionState } from '../../actions/generalActions';

const OfflineScreen = (props) => {
  const { isOffline, updateNetworkConnectionState } = props;

  const handleNetworkChange = (event) => {
    updateNetworkConnectionState(event.type === 'online' ? false : true);
  };

  useEffect(() => {
    window.addEventListener('online', handleNetworkChange);
    window.addEventListener('offline', handleNetworkChange);

    return () => {
      window.removeEventListener('online', handleNetworkChange);
      window.removeEventListener('offline', handleNetworkChange);
    };
  }, []);

  return isOffline ? (
    <div className='offline-dialog-container'>
      <div className='offline-dialog'>
        <div className='offline-dialog-title'>You are offline!</div>
        <div className='offline-dialog-text'>
          You can resume once your internet connection is back.
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

const mapStateToProps = (state) => ({
  isOffline: get(state, 'generalReducer.isOffline', false),
});

export default connect(mapStateToProps, { updateNetworkConnectionState })(
  OfflineScreen
);

import React from 'react';

function Col(props) {
  let { className, style, onClick, boxRef, id, ...rest } = props;

  return (
    <div
      ref={boxRef}
      className={className ? className + ' col-flex' : 'col-flex'}
      id={id ? id : ''}
      style={style}
      onClick={onClick || null}
      {...rest}
    >
      {props.children}
    </div>
  );
}

export default Col;

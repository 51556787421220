const BlockedCamIcon = ({ className }) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
  >
    <path
      d='M15.2333 4.5H3.00082C2.60299 4.5 2.22146 4.65804 1.94015 4.93934C1.65885 5.22064 1.50082 5.60218 1.50082 6V18C1.49712 18.0725 1.49712 18.145 1.50082 18.2175L15.2333 4.5Z'
      fill='white'
    />
    <path
      d='M22.095 6.0825C21.9758 6.02555 21.8436 6.00111 21.7119 6.01165C21.5802 6.02218 21.4536 6.06732 21.345 6.1425L17.25 9.045V7.83L22.5 2.58L21.42 1.5L1.5 21.42L2.58 22.5L5.58 19.5H15.75C16.1478 19.5 16.5294 19.342 16.8107 19.0607C17.092 18.7794 17.25 18.3978 17.25 18V14.955L21.315 17.8575C21.4269 17.9372 21.5586 17.9846 21.6957 17.9946C21.8327 18.0045 21.9699 17.9766 22.0922 17.9139C22.2144 17.8513 22.3171 17.7562 22.3891 17.6391C22.461 17.522 22.4994 17.3874 22.5 17.25V6.75C22.5003 6.61228 22.4626 6.47714 22.3912 6.35939C22.3198 6.24165 22.2173 6.14585 22.095 6.0825Z'
      fill='white'
    />
  </svg>
);

export default BlockedCamIcon;

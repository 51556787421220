import { STORE_CHILD_DETAILS, STORE_SCHOOLS_LIST } from '../actions/types';

const initialState = {
  linkedChildDetails: [],
  schoolsList: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case STORE_CHILD_DETAILS:
      const data = action.payload ? action.payload : [];
      return {
        ...state,
        linkedChildDetails: data,
      };
    case STORE_SCHOOLS_LIST:
      return {
        ...state,
        schoolsList: action.payload,
      };
    default:
      return state;
  }
};

import axios from 'axios';
import { get } from 'lodash';
import * as Sentry from '@sentry/react';

const prefix = process.env.REACT_APP_BASE_URL;

export const makeApiCall = (method, path, data, config) => {
  if (method === 'GET') {
    return axios
      .get(prefix + path, { timeout: 30000, ...config })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        Sentry.captureException(err);
        if (err?.response?.status === 401) {
          localStorage.removeItem('persist:root');
          window.location.href = '/';
          return;
        }
        if (get(err, 'response.data.data.error', false)) {
          return { error: err.response.data.data.error[0] };
        }
        return {
          statusCode: err?.response?.status,
          errorCode: err.message,
          error:
            'Something went wrong. Please try again or reach out to us at support@logiqids.com',
        };
      });
  } else if (method === 'POST') {
    return axios
      .post(prefix + path, { ...data }, { timeout: 30000, ...config })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        Sentry.captureException(err);
        if (err?.response?.status === 401) {
          localStorage.removeItem('persist:root');
          window.location.href = '/';
          return;
        }
        if (get(err, 'response.data.data.error', false)) {
          return { error: err.response.data.data.error[0] };
        }
        return {
          statusCode: err?.response?.status,
          errorCode: err.message,
          error:
            'Something went wrong. Please try again or reach out to us at support@logiqids.com',
        };
      });
  }
};

export const fetchFromExternalLinks = (url) =>
  fetch(url)
    .then((res) => res.json())
    .then((res) => ({ data: res }))
    .catch((err) => {
      Sentry.captureException(err);
      return {
        statusCode: err?.response?.status,
        error:
          'Something went wrong. Please try again or reach out to us at support@logiqids.com',
      };
    });

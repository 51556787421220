import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
  Typography,
} from '@material-ui/core';

import './style.scss';

const DefaultModal = (props) => {
  const { handleClose, instructionType } = props;

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      classes={{ paper: 'dialogContainer' }}
    >
      <DialogTitle className='dialogTitle'>
        <Typography variant='h6' style={{ fontWeight: 600 }}>
          {`${instructionType === 'cam' ? 'Camera' : 'Microphone'}`} is Blocked
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant='subtitle1'>
          LQ Champs requires access to your{' '}
          {`${instructionType === 'cam' ? 'Camera' : 'Microphone'}`} for the
          better experience in the classroom
        </Typography>
      </DialogContent>
      <DialogActions className='dialogActions'>
        <Button className='dismissBtn' onClick={handleClose}>
          Dismiss
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DefaultModal;

import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { CircularProgress } from '@material-ui/core';

import './style.scss';
import { detectBrowser } from '../../Util/getDeviceInformation';
import {
  checkPermissions,
  getCameraAccess,
  getMicrophoneAccess,
  getCameraPermissionState,
  getMicPermissionState,
} from '../../Util/checkPermissions';
import { setSettingsDialog } from '../../actions/generalActions';
import Col from '../Elements/Flex/Col';
import Row from '../Elements/Flex/Row';
import PermissionModal from '../Classroom/PermissionModal/PermissionModal';
import PermissionMessage from '../Common/PermissionMessage/PermissionMessage';

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkingPermission: true,
      permissionMessage: false,
      permissionModal: undefined,
      cam: { permission: false, error: '' },
      mic: { permission: false, error: '' },
      browserState: { status: '', text: '' },
      warningMessage: false,
    };
  }

  async componentDidMount() {
    const { context, history, setSettingsDialog, ssid } = this.props;

    const browser = detectBrowser();
    const browserState = { status: '', text: '' };

    if (
      browser.indexOf('Chrome') !== -1 ||
      browser.indexOf('Firefox') !== -1 ||
      browser.indexOf('Edg') !== -1 ||
      browser.indexOf('Safari') !== -1
    ) {
      browserState.status = 'good';
      browserState.text = 'Compatible';
    } else {
      browserState.status = 'bad';
      browserState.text = 'Not compatible';
    }

    this.setState({ browser, browserState });

    checkPermissions(browser).then((res) => {
      this.setState({
        ...res,
        permissionMessage: false,
        checkingPermission: false,
      });
      if (
        context === 'join-class' &&
        res.mic.permission &&
        res.cam.permission
      ) {
        setSettingsDialog({ show: false });
        history.push(`classroom/${ssid}`);
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { mic, cam } = this.state;

    if (
      (prevState.mic !== mic || prevState.cam !== cam) &&
      mic.permission &&
      cam.permission
    )
      this.setState({ warningMessage: false });
  }

  closePopup = () => {
    this.props.setSettingsDialog({ show: false });
  };

  allowMic = async () => {
    const { browser } = this.state;

    getMicrophoneAccess().then((res) => {
      const updatedState = {
        permissionModal: !res.permission ? 'mic' : undefined,
        permissionMessage: false,
      };

      updatedState.mic = { ...res };

      this.setState(updatedState);
    });

    if (browser.indexOf('Chrome') !== -1 || browser.indexOf('Edg') !== -1) {
      const state = await getMicPermissionState();
      if (state === 'prompt') this.setState({ permissionMessage: true });
    }
  };

  allowCamera = async () => {
    const { browser } = this.state;

    getCameraAccess().then((res) => {
      const updatedState = {
        permissionModal: !res.permission ? 'cam' : undefined,
        permissionMessage: false,
      };

      updatedState.cam = { ...res };

      this.setState(updatedState);
    });

    if (browser.indexOf('Chrome') !== -1 || browser.indexOf('Edg') !== -1) {
      const state = await getCameraPermissionState();
      if (state === 'prompt') this.setState({ permissionMessage: true });
    }
  };

  closePermissionMessage = () => {
    this.setState({ permissionModal: undefined });
  };

  goToClass = () => {
    const { setSettingsDialog, history, ssid } = this.props;
    const { mic, cam, warningMessage } = this.state;

    if (mic.permission && cam.permission) {
      setSettingsDialog({ show: false });
      history.push(`classroom/${ssid}`, { from: 'dashboard' });
    } else if (!warningMessage) {
      this.setState({ warningMessage: true });
    }
  };

  render() {
    const {
      browserState,
      permissionModal,
      permissionMessage,
      cam,
      mic,
      warningMessage,
      checkingPermission,
    } = this.state;
    const { context } = this.props;

    return (
      <>
        {permissionModal && (
          <PermissionModal
            {...{
              handleClose: this.closePermissionMessage,
              instructionType: permissionModal,
            }}
          />
        )}
        {permissionMessage && <PermissionMessage />}
        <div className='popup-container'>
          <div className='popup'>
            <div className='close-btn' onClick={this.closePopup}>
              <svg
                width='12'
                height='12'
                viewBox='0 0 12 12'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M11.3346 1.74225L10.2604 0.667969L6.0013 4.92702L1.74225 0.667969L0.667969 1.74225L4.92702 6.0013L0.667969 10.2604L1.74225 11.3346L6.0013 7.07559L10.2604 11.3346L11.3346 10.2604L7.07559 6.0013L11.3346 1.74225Z'
                  fill='#0D0D0D'
                />
              </svg>
            </div>
            <Col className='header align-start'>
              <div className='icon'></div>
              <div className='heading'>BEFORE WE BEGIN</div>
              <div className='title'>Let’s help you setup</div>
              <div className='subtitle'>
                In order to enjoy the best class experience, please allow access
                to the following hardware
              </div>
            </Col>
            <Col className='settings-body'>
              <Row className='permission-row space-between'>
                <Row>
                  <div className='icon'>
                    <svg
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M16.215 8.691L22.9298 7.01175C23.6362 8.57027 24.0011 10.2619 24 11.973C24 18.57 18.57 24 11.9692 24C11.664 24 11.3632 23.988 11.0625 23.9647L15.9653 15.6165C16.32 15.2415 16.6215 14.8155 16.848 14.3475C17.199 13.6043 17.3774 12.7914 17.37 11.9696C17.3626 11.1478 17.1696 10.3382 16.8053 9.6015C16.6407 9.27806 16.4429 8.97265 16.215 8.69025V8.691ZM12.9728 17.2658L10.8517 23.9445C4.73775 23.3438 0 18.1403 0 11.9767C0 9.594 0.711 7.2615 2.03925 5.277L6.8715 13.656C7.0515 14.1953 7.99275 16.2383 9.93825 16.9837C10.938 17.3625 11.9805 17.4645 12.9735 17.2658H12.9728ZM11.973 7.62075C9.969 7.641 8.094 9.1635 7.69125 11.133C7.55129 11.855 7.5911 12.6004 7.80717 13.3034C8.02324 14.0064 8.40893 14.6455 8.93025 15.1642C10.1452 16.3522 12.0705 16.7423 13.6642 16.0905C15.0705 15.5168 16.1332 14.1645 16.3552 12.6608C16.6252 10.806 15.5662 8.829 13.8593 8.03175C13.2683 7.75792 12.6243 7.61784 11.973 7.6215V7.62075ZM7.047 9.96075L2.25825 4.965C3.3748 3.42804 4.8391 2.17701 6.53154 1.31411C8.22397 0.451204 10.0965 0.000907686 11.9963 0C14.235 0.00154796 16.4291 0.627024 18.3322 1.80623C20.2353 2.98543 21.772 4.67169 22.77 6.67575H12.5588C10.746 6.5505 9.27 7.19475 8.29275 8.1285C7.75287 8.64451 7.32831 9.26894 7.047 9.96075Z'
                        fill='#6C757D'
                      />
                    </svg>
                  </div>
                  <Col className='align-start'>
                    <div className='title'>Web browser</div>
                    <div className='subtitle'>Use compatible web browser</div>
                  </Col>
                </Row>
                <Row
                  className={`browser-status ${
                    browserState.status === 'good' ? 'good' : 'bad'
                  }`}
                >
                  {browserState.text}
                </Row>
              </Row>
              <Row className='permission-row space-between'>
                <Row>
                  <div className='icon'>
                    <svg
                      width='16'
                      height='24'
                      viewBox='0 0 16 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M3.5 4.5C3.5 3.30653 3.97411 2.16193 4.81802 1.31802C5.66193 0.474106 6.80653 0 8 0C9.19347 0 10.3381 0.474106 11.182 1.31802C12.0259 2.16193 12.5 3.30653 12.5 4.5V12C12.5 13.1935 12.0259 14.3381 11.182 15.182C10.3381 16.0259 9.19347 16.5 8 16.5C6.80653 16.5 5.66193 16.0259 4.81802 15.182C3.97411 14.3381 3.5 13.1935 3.5 12V4.5Z'
                        fill='#6C757D'
                      />
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M1.25 9.75C1.44891 9.75 1.63968 9.82902 1.78033 9.96967C1.92098 10.1103 2 10.3011 2 10.5V12C2 13.5913 2.63214 15.1174 3.75736 16.2426C4.88258 17.3679 6.4087 18 8 18C9.5913 18 11.1174 17.3679 12.2426 16.2426C13.3679 15.1174 14 13.5913 14 12V10.5C14 10.3011 14.079 10.1103 14.2197 9.96967C14.3603 9.82902 14.5511 9.75 14.75 9.75C14.9489 9.75 15.1397 9.82902 15.2803 9.96967C15.421 10.1103 15.5 10.3011 15.5 10.5V12C15.5 13.8593 14.8094 15.6523 13.5622 17.0312C12.3149 18.4101 10.6 19.2766 8.75 19.4625V22.5H13.25C13.4489 22.5 13.6397 22.579 13.7803 22.7197C13.921 22.8603 14 23.0511 14 23.25C14 23.4489 13.921 23.6397 13.7803 23.7803C13.6397 23.921 13.4489 24 13.25 24H2.75C2.55109 24 2.36032 23.921 2.21967 23.7803C2.07902 23.6397 2 23.4489 2 23.25C2 23.0511 2.07902 22.8603 2.21967 22.7197C2.36032 22.579 2.55109 22.5 2.75 22.5H7.25V19.4625C5.40003 19.2766 3.68506 18.4101 2.43782 17.0312C1.19058 15.6523 0.499977 13.8593 0.5 12V10.5C0.5 10.3011 0.579018 10.1103 0.71967 9.96967C0.860322 9.82902 1.05109 9.75 1.25 9.75Z'
                        fill='#6C757D'
                      />
                    </svg>
                  </div>
                  <Col className='align-start'>
                    <div className='title'>Allow microphone</div>
                    <div className='subtitle'>Talk to everyone in class</div>
                  </Col>
                </Row>
                <button
                  onClick={this.allowMic}
                  className={`${mic.permission ? 'good' : 'allow-btn'}`}
                  disabled={mic.permission || checkingPermission}
                >
                  {checkingPermission ? (
                    <CircularProgress size={22} style={{ color: 'white' }} />
                  ) : mic.permission ? (
                    'Allowed'
                  ) : (
                    'Allow'
                  )}
                </button>
              </Row>
              <Row className='permission-row space-between'>
                <Row>
                  <div className='icon'>
                    <svg
                      width='24'
                      height='16'
                      viewBox='0 0 24 16'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                      style={{ marginTop: 4 }}
                    >
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M0 3.5C0 2.70435 0.316071 1.94129 0.87868 1.37868C1.44129 0.81607 2.20435 0.5 3 0.5H14.25C14.9776 0.499914 15.6805 0.764289 16.2277 1.24389C16.7749 1.72349 17.1292 2.38563 17.2245 3.107L21.8895 1.034C22.1178 0.932257 22.368 0.889195 22.6172 0.908728C22.8664 0.928261 23.1068 1.00977 23.3165 1.14584C23.5262 1.28192 23.6986 1.46824 23.8179 1.68789C23.9373 1.90753 23.9999 2.15352 24 2.4035V13.5965C23.9998 13.8463 23.9372 14.0921 23.8179 14.3115C23.6986 14.531 23.5264 14.7172 23.3169 14.8532C23.1074 14.9893 22.8673 15.0708 22.6183 15.0905C22.3693 15.1102 22.1193 15.0674 21.891 14.966L17.2245 12.893C17.1292 13.6144 16.7749 14.2765 16.2277 14.7561C15.6805 15.2357 14.9776 15.5001 14.25 15.5H3C2.20435 15.5 1.44129 15.1839 0.87868 14.6213C0.316071 14.0587 0 13.2956 0 12.5V3.5Z'
                        fill='#6C757D'
                      />
                    </svg>
                  </div>
                  <Col className='align-start'>
                    <div className='title'>Allow camera</div>
                    <div className='subtitle'>Let everyone see you</div>
                  </Col>
                </Row>
                <button
                  onClick={this.allowCamera}
                  className={`${cam.permission ? 'good' : 'allow-btn '}`}
                  disabled={cam.permission || checkingPermission}
                >
                  {checkingPermission ? (
                    <CircularProgress size={22} style={{ color: 'white' }} />
                  ) : cam.permission ? (
                    'Allowed'
                  ) : (
                    'Allow'
                  )}
                </button>
              </Row>
              {warningMessage && (
                <p className='warning-message'>
                  Please allow{' '}
                  {`${!cam.permission ? 'Camera' : ''} ${
                    !cam.permission && !mic.permission ? 'and' : ''
                  } ${!mic.permission ? 'Microphone' : ''}`}{' '}
                  permission
                </p>
              )}
              {context === 'join-class' && (
                <button className='continue-btn' onClick={this.goToClass}>
                  Continue
                </button>
              )}
            </Col>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  context: state.generalReducer.settingsDialog.context,
  ssid: state.generalReducer.settingsDialog.ssid,
});

export default withRouter(
  connect(mapStateToProps, { setSettingsDialog })(Settings)
);

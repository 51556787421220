import React from 'react';

import './styles.scss';
import Row from '../../Elements/Flex/Row';
import Loader from '../../Elements/Loader/Loader';

const FullPageLoader = () => (
  <Row className='full-page-loader'>
    <Loader />
  </Row>
);

export default FullPageLoader;

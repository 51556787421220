import React from 'react';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import './style.scss';
import Row from '../../../Elements/Flex/Row';
import Col from '../../../Elements/Flex/Col';
import MicInstructionIcon from './icons/MicInstructionIcon'
import CamInstructionIcon from './icons/CamInstructionIcon'
import BlockedCamIcon from './icons/BlockedCamIcon';
import BlockedMicIcon from './icons/BlockedMicIcon';
import RightTopArrowIcon from './icons/RightTopArrowIcon';

const ChromeModal = (props) => {
  const { handleClose, instructionType } = props;

  return (
    <Col className='chrome-permission-container'>
      <IconButton size='small' className='close-btn' onClick={handleClose}>
        <Close className='close-icon' />
      </IconButton>
      <RightTopArrowIcon className="right-top-arrow" />
      <Col className='instructions'>
        {instructionType === 'mic' ? (
          <MicInstructionIcon className="chrome-instruction" />
        ) : (
          <CamInstructionIcon className="chrome-instruction" />
        )}
        <h2 className='title'>
          Your{' '}
          {`${
            instructionType === 'mic'
              ? 'Mic'
              : instructionType === 'cam'
              ? 'Camera'
              : 'Microphone'
          }`}{' '}
          is blocked
        </h2>
        <p className='subtitle'>
          LogIQids needs access to your{' '}
          {`${
            instructionType === 'mic'
              ? 'Mic'
              : instructionType === 'cam'
              ? 'Camera'
              : 'Microphone'
          }`}
          . To use LogIQids -
        </p>
        <Col className='steps'>
          <Row className='step align-center'>
            <Row className='step-no'>1</Row>
            <Row className='align-center'>
              Click on {`${instructionType === 'mic' ? 'Mic' : 'Camera'}`}{' '}
              Blocked icon{' '}
              {instructionType === 'mic' ? (
                <BlockedMicIcon className="blocked-icon" />
              ) : (
                <BlockedCamIcon className="blocked-icon" />
              )}{' '}
              in your browser's address bar
            </Row>
          </Row>
          <Row className='step align-center'>
            <Row className='step-no'>2</Row>
            <p>
              Select always allow lq-live.logiqids.com to access your{' '}
              {`${
                instructionType === 'mic'
                  ? 'Mic'
                  : instructionType === 'cam'
                  ? 'Camera'
                  : 'Camera and microphone'
              }`}
            </p>
          </Row>
          <Row className='step align-center'>
            <Row className='step-no'>3</Row>
            <p>Refresh the page.</p>
          </Row>
        </Col>
      </Col>
    </Col>
  );
};

export default ChromeModal;

import FingerprintJS from '@fingerprintjs/fingerprintjs';
import axios from 'axios';

import {
  SET_OPENED_CLASS,
  SET_IP_DATA,
  UPDATE_NETWORK_CONNECTION_STATE,
  UPDATE_SHOW_PASSWORD_SET_RESPONSE,
  SET_SETTINGS_DIALOG,
  SET_SUBSCRIPTION_ID,
  SET_SUBSCRIPTION_TRACKS,
  SET_VISITOR_ID,
  UPDATE_SHOW_REFERRAL_STRIP,
  UPDATE_SHOW_CHALLENGE_STRIP,
  SET_SUPPORT_DIALOG,
  SET_UTM_PARAMETERS,
  SET_SESSION_SDK,
  UPDATE_XPLORE,
  SET_SIGNUP_TYPE,
  HIDE_BOOK_DOUBT_POPOVER,
  HIDE_SUMMER_CAMP_MODAL,
} from './types';

export const setOpenedClass = (data) => ({
  type: SET_OPENED_CLASS,
  payload: data,
});

export const setIpData = (data) => ({
  type: SET_IP_DATA,
  payload: data,
});

export const updateShowPasswordSetResponse = (data) => ({
  type: UPDATE_SHOW_PASSWORD_SET_RESPONSE,
  payload: data,
});

export const setSettingsDialog = (data) => ({
  type: SET_SETTINGS_DIALOG,
  payload: data,
});

export const updateNetworkConnectionState = (data) => ({
  type: UPDATE_NETWORK_CONNECTION_STATE,
  payload: data,
});

export const setSubscriptionId = (data) => ({
  type: SET_SUBSCRIPTION_ID,
  payload: data,
});

export const setSubscriptionTracks = (data) => ({
  type: SET_SUBSCRIPTION_TRACKS,
  payload: data,
});

export const setSupportDialog = (data) => ({
  type: SET_SUPPORT_DIALOG,
  payload: data,
});

export const setVisitorId = () => (dispatch, state) => {
  const { visitorId } = state().generalReducer;
  if (visitorId) axios.defaults.headers.common['Session-Id'] = visitorId;
  else {
    return new Promise((resolve, reject) => {
      const fpPromise = FingerprintJS.load();
      fpPromise
        .then((fp) => fp.get())
        .then((result) => {
          if (result && result.visitorId) {
            axios.defaults.headers.common['Session-Id'] = result.visitorId;
            dispatch({
              type: SET_VISITOR_ID,
              payload: result.visitorId,
            });
            return resolve(result.visitorId);
          } else {
            return reject(false);
          }
        });
    });
  }
};

export const updateShowReferralStrip = (state) => ({
  type: UPDATE_SHOW_REFERRAL_STRIP,
  payload: state,
});

export const updateShowChallengeStrip = (state) => ({
  type: UPDATE_SHOW_CHALLENGE_STRIP,
  payload: state,
});

export const setUtmParameters = (state) => ({
  type: SET_UTM_PARAMETERS,
  payload: state,
});

export const setSessionSdk = (state) => ({
  type: SET_SESSION_SDK,
  payload: state,
});

export const updateXploreData = (data) => ({
  type: UPDATE_XPLORE,
  payload: data,
});

export const setSignupType = (data) => ({
  type: SET_SIGNUP_TYPE,
  payload: data,
});

export const hideBookDoubtPopover = () => ({
  type: HIDE_BOOK_DOUBT_POPOVER,
});

export const hideSummerCampModal = () => ({
  type: HIDE_SUMMER_CAMP_MODAL,
});

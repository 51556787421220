import {
  UPDATE_CREDITS_MODAL,
  UPDATE_PASSWORD_MODAL,
  UPDATE_PAST_JOURNEY_MODAL,
} from '../actions/types';

const initialState = {
  passwordModal: false,
  classCreditsModal: false,
  pastJourneyModal: false,
};

const headerReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PASSWORD_MODAL:
      return {
        ...state,
        passwordModal: action.payload,
      };
    case UPDATE_CREDITS_MODAL:
      return {
        ...state,
        classCreditsModal: action.payload,
      };
    case UPDATE_PAST_JOURNEY_MODAL:
      return {
        ...state,
        pastJourneyModal: action.payload,
      };
    default:
      return state;
  }
};

export default headerReducer;

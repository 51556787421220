export const detectBrowser = () => {
  const userAgentString = navigator.userAgent;
  let browser;

  if (userAgentString.indexOf('Firefox') > -1) {
    // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
    browser = userAgentString
      .split(' ')
      .filter((str) => str.includes('Firefox'))[0];
  } else if (userAgentString.indexOf('SamsungBrowser') > -1) {
    // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36
    browser = 'Samsung Browser';
  } else if (
    userAgentString.indexOf('Opera') > -1 ||
    userAgentString.indexOf('OPR') > -1
  ) {
    browser =
      userAgentString.split(' ').filter((str) => str.includes('OPR'))[0] ||
      userAgentString.split(' ').filter((str) => str.includes('Opera'))[0];
    // "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
  } else if (userAgentString.indexOf('Trident') > -1) {
    browser = 'Microsoft Internet Explorer';
    // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
  } else if (userAgentString.indexOf('Edge') > -1) {
    browser = `Microsoft Edge (Legacy) ${
      userAgentString.split(' ').filter((str) => str.includes('Edge'))[0]
    }`;
    // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
  } else if (userAgentString.indexOf('Edg') > -1) {
    browser = `Microsoft Edge (Chromium) ${
      userAgentString.split(' ').filter((str) => str.includes('Edg'))[0]
    }`;
    // Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/91.0.4472.124 Safari/537.36 Edg/91.0.864.64
  } else if (userAgentString.indexOf('Chrome') > -1) {
    browser = userAgentString
      .split(' ')
      .filter((str) => str.includes('Chrome'))[0];
    // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
  } else if (userAgentString.indexOf('Safari') > -1) {
    browser = userAgentString
      .split(' ')
      .filter((str) => str.includes('Safari'))[0];
    // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
  } else {
    browser = 'unknown';
  }

  return browser;
};

export const detectOS = () => {
  const userAgent = navigator.userAgent;
  let osName = 'Not known';

  if (userAgent.indexOf('Win') !== -1) osName = 'Windows OS';
  if (userAgent.indexOf('Mac') !== -1) osName = 'Mac OS';
  if (userAgent.indexOf('Linux') !== -1) osName = 'Linux OS';
  if (userAgent.indexOf('Android') !== -1) osName = 'Android OS';
  if (userAgent.indexOf('like Mac') !== -1) osName = 'iOS';

  return osName;
};

export const getScreenResolution = () => {
  return `${window.screen.width * window.devicePixelRatio} * ${
    window.screen.height * window.devicePixelRatio
  }`;
};

export const detectDeviceType = () => {
  const userAgent = navigator.userAgent;

  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(userAgent)) {
    return 'Tablet';
  } else if (
    /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      userAgent
    )
  ) {
    return 'Mobile';
  }
  return 'Desktop';
};

const LeftTopArrowIcon = ({ className }) => (
  <svg
    width='82'
    height='82'
    viewBox='0 0 82 82'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
  >
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M3.19673 34.8511C2.91134 34.2073 2.83688 33.4909 2.98377 32.8023C3.13067 32.1137 3.49156 31.4872 4.01602 31.0104L32.7054 4.92166C33.265 4.41274 33.9799 4.10228 34.7372 4.03939C35.4944 3.97651 36.2508 4.16479 36.8867 4.57444L69.4863 25.5736C70.0817 25.9576 70.5404 26.5159 70.7985 27.1706C71.0566 27.8253 71.1012 28.5437 70.926 29.2255C70.7507 29.9072 70.3645 30.5184 69.8212 30.9736C69.2779 31.4287 68.6046 31.7052 67.8954 31.7644L54.3096 32.8926C56.1934 50.9527 60.3916 59.3551 64.5336 63.407C68.4705 67.2592 72.7592 67.6684 77.2512 68.0912L78.4089 68.2017C79.2376 68.2848 80.0064 68.6644 80.5707 69.2689C81.1349 69.8734 81.4556 70.6613 81.4726 71.4842C81.4895 72.3071 81.2015 73.1084 80.6627 73.7373C80.1238 74.3662 79.3714 74.7794 78.5468 74.8991C69.2381 76.2546 55.6075 76.5128 43.7235 70.9262C32.1891 65.5037 22.777 54.7979 20.301 35.7167L6.60634 36.854C5.89694 36.9131 5.18701 36.7518 4.57572 36.3928C3.96444 36.0337 3.48232 35.4947 3.19673 34.8511Z'
      fill='white'
    />
  </svg>
);

export default LeftTopArrowIcon;

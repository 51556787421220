const getCameraPermissionState = () =>
  navigator.permissions.query({ name: 'camera' }).then((res) => res.state);

const getMicPermissionState = () =>
  navigator.permissions.query({ name: 'microphone' }).then((res) => res.state);

const getMicrophoneAccess = () =>
  navigator.mediaDevices
    .getUserMedia({ audio: true })
    .then((tracks) => {
      tracks.getTracks().forEach((track) => track.stop());
      return { permission: true, error: '' };
    })
    .catch((error) => {
      console.error(error.name, error.message);
      return { permission: false, error };
    });

const getCameraAccess = () =>
  navigator.mediaDevices
    .getUserMedia({ video: true })
    .then((tracks) => {
      tracks.getTracks().forEach((track) => track.stop());
      return { permission: true, error: '' };
    })
    .catch((error) => {
      console.error(error.name, error.message);
      return { permission: false, error };
    });

const checkPermissions = (browser) => {
  if (browser.indexOf('Safari') === -1 && browser.indexOf('Firefox') === -1) {
    return Promise.all([
      getCameraPermissionState(),
      getMicPermissionState(),
    ]).then((res) => ({
      cam: { permission: res[0] === 'granted', error: '' },
      mic: { permission: res[1] === 'granted', error: '' }
    }));
  } else {
    return Promise.all([getMicrophoneAccess(), getCameraAccess()]).then(
      (res) => ({
        mic: res[0],
        cam: res[1],
      })
    );
  }
};

export {
  checkPermissions,
  getMicrophoneAccess,
  getCameraAccess,
  getMicPermissionState,
  getCameraPermissionState,
};

import React from 'react';

import './style.scss'

const PermissionMessage = () => (
  <div className='permission-message-container'>
    <div className='arrow'>
      <svg
        width='82'
        height='82'
        viewBox='0 0 82 82'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M3.19584 34.8511C2.91045 34.2073 2.836 33.4909 2.98289 32.8023C3.12978 32.1137 3.49068 31.4872 4.01514 31.0104L32.7045 4.92166C33.2641 4.41274 33.979 4.10228 34.7363 4.03939C35.4936 3.97651 36.2499 4.16479 36.8858 4.57444L69.4855 25.5736C70.0808 25.9576 70.5395 26.5159 70.7976 27.1706C71.0557 27.8253 71.1003 28.5437 70.9251 29.2255C70.7499 29.9072 70.3636 30.5184 69.8203 30.9736C69.277 31.4287 68.6037 31.7052 67.8945 31.7644L54.3088 32.8926C56.1925 50.9527 60.3907 59.3551 64.5327 63.407C68.4696 67.2592 72.7583 67.6684 77.2503 68.0912L78.408 68.2017C79.2368 68.2848 80.0055 68.6644 80.5698 69.2689C81.134 69.8734 81.4548 70.6613 81.4717 71.4842C81.4886 72.3071 81.2006 73.1084 80.6618 73.7373C80.123 74.3662 79.3705 74.7794 78.5459 74.8991C69.2372 76.2546 55.6066 76.5128 43.7226 70.9262C32.1882 65.5037 22.7761 54.7979 20.3001 35.7167L6.60546 36.854C5.89605 36.9131 5.18612 36.7518 4.57484 36.3928C3.96355 36.0337 3.48144 35.4947 3.19584 34.8511Z'
          fill='white'
        />
      </svg>
    </div>
    <div className='message'>
      Select <span style={{ color: '#006AFF' }}>ALLOW</span> to let LogIQids use
      your mic and camera to make the best learning experience for you
    </div>
  </div>
);

export default PermissionMessage

import React from 'react';

function Row(props) {
  let { className, style, onClick, boxRef, id, ...restProps } = props;

  return (
    <div
      ref={boxRef}
      className={className ? className + ' row-flex' : 'row-flex'}
      id={id ? id : ''}
      style={style}
      onClick={onClick || null}
      {...restProps}
    >
      {props.children}
    </div>
  );
}

export default Row;

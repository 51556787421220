import { initializeApp } from 'firebase/app';
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from 'firebase/auth';

const initializeFirebase = () => {
  // using logiqids firebase credentials
  initializeApp({
    apiKey: 'AIzaSyCsAskJj09BpZYTxh7XH3b9mGGv7fRgCvY',
    authDomain: 'logiqids.firebaseapp.com',
    projectId: 'logiqids-8641',
    storageBucket: 'logiqids-8641.appspot.com',
    messagingSenderId: '628445493199',
    appId: '1:628445493199:web:e6f8c9d06e9437c3bc44ba',
  });

  if (!document.getElementById('recaptcha-container')) {
    const recaptchaDiv = document.createElement('div');
    recaptchaDiv.id = 'recaptcha-container';
    recaptchaDiv.style.display = 'none';

    document.getElementsByTagName('body')[0].appendChild(recaptchaDiv);
  }

  const auth = getAuth();
  if (!window.recaptchaVerifier) {
    window.recaptchaVerifier = new RecaptchaVerifier(
      'recaptcha-container',
      { size: 'invisible' },
      auth
    );
  }
};

const sendFirebaseOtp = async (data) => {
  const { countryPhone, phoneNumber, onSuccess, onError } = data;

  const auth = getAuth();

  try {
    if (!document.getElementById('recaptcha-container')) {
      const recaptchaDiv = document.createElement('div');
      recaptchaDiv.id = 'recaptcha-container';
      recaptchaDiv.style.display = 'none';

      document.getElementsByTagName('body')[0].appendChild(recaptchaDiv);
    }

    const confirmationResult = await signInWithPhoneNumber(
      auth,
      `${countryPhone}${phoneNumber}`,
      window.recaptchaVerifier
    );

    onSuccess();
    window.confirmationResult = confirmationResult;

    return 'success';
  } catch (error) {
    onError(
      error.message.indexOf('invalid-phone-number') !== -1
        ? 'Phone Number is not valid'
        : error.message
    );

    return 'error';
  }
};

const verifyFirebaseOtp = async (data) => {
  const { otp, onSuccess, onError } = data;

  try {
    const result = await window.confirmationResult.confirm(otp);

    onSuccess(result?.user?.accessToken);
    document.getElementById('recaptcha-container').remove();
    window.recaptchaVerifier = undefined;
    return 'success';
  } catch (error) {
    console.error(error);
    onError('Oops! Wrong OTP entered. Please resend OTP and try again.');
    return 'error';
  }
};

export { initializeFirebase, sendFirebaseOtp, verifyFirebaseOtp };

const RightTopArrowIcon = ({ className }) => (
  <svg
    width='89'
    height='88'
    viewBox='0 0 89 88'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
  >
    <g clipPath='url(#clip0_18387_76725)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M82.2251 37.8511C82.5105 37.2073 82.585 36.4909 82.4381 35.8023C82.2912 35.1137 81.9303 34.4872 81.4059 34.0104L52.7165 7.92166C52.1569 7.41274 51.442 7.10228 50.6847 7.03939C49.9274 6.97651 49.1711 7.16479 48.5352 7.57444L15.9355 28.5736C15.3402 28.9576 14.8814 29.5159 14.6234 30.1706C14.3653 30.8253 14.3207 31.5437 14.4959 32.2255C14.6711 32.9072 15.0574 33.5184 15.6007 33.9736C16.144 34.4287 16.8173 34.7052 17.5265 34.7644L31.1122 35.8926C29.2285 53.9527 25.0303 62.3551 20.8883 66.407C16.9514 70.2592 12.6627 70.6684 8.17066 71.0912L7.01296 71.2017C6.18424 71.2848 5.41544 71.6644 4.85122 72.2689C4.287 72.8734 3.96623 73.6613 3.94929 74.4842C3.93234 75.3071 4.22038 76.1084 4.75921 76.7373C5.29804 77.3662 6.05051 77.7794 6.87506 77.8991C16.1838 79.2546 29.8144 79.5128 41.6984 73.9262C53.2328 68.5037 62.6449 57.7979 65.1208 38.7167L78.8155 39.854C79.5249 39.9131 80.2349 39.7518 80.8462 39.3928C81.4574 39.0337 81.9396 38.4947 82.2251 37.8511Z'
        fill='white'
      />
    </g>
    <defs>
      <clipPath id='clip0_18387_76725'>
        <rect
          width='81'
          height='82'
          fill='white'
          transform='matrix(0.0827578 -0.99657 -0.99657 -0.0827578 81.7188 87.5078)'
        />
      </clipPath>
    </defs>
  </svg>
);

export default RightTopArrowIcon
